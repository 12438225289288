<template>
    <div class="ch_list_wrap">
        <div class="ch_list_box" v-for="curation in curationData" :key="`ch${curation.m_idx}`">
            <div class="ch_list_img">
                <img :src="returnItemImage(curation)" alt="">
            </div>
            <div class="ch_list_info">
                <div class="ch_list_title">{{ curation.i_name }}</div>
                <div class="ch_list_date">{{ returnBidDate(curation.m_bid_date, 'YYYY.MM.DD') }}</div>
                <div class="ch_list_price">
                    <div class="price_text">{{ $t('sale_price') }}</div>
                    <div class="mingle_img"></div>
                    <div class="price">{{ returnPrice(curation.m_bid_price) }}Mg</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import util from "@/mixins/util";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "CurationListHistoryLayout",
    mixins: [imageOption, dateMixins, imageResizeMixins],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        curationData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return{

        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        isBid(curation) {
            return !util.isEmpty(curation) && curation.m_status === 2;
        },
        returnItemImage(curation) {
            if(util.isEmpty(curation) || util.isEmpty(curation.ItemFile) || util.isEmpty(curation.ItemFile.Item)) {
                return "";
            }
            //return `${curation.ItemFile.Item[0].org_url}?${this.setImageOptions(278, null, null)}`;
            return this.setFitWidthImg(curation.ItemFile.Item, 278);
        },
        returnBidDate(date) {
            if(util.isEmpty(date)) {
                return "";
            }
            return this.returnDateFormat(date, 'YYYY.MM.DD');
        },
        returnPrice(num) {
            if(util.isEmpty(num) || isNaN(num)) {
                return 0;
            }
            return util.Number.numFormat(num);
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
